import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EventsChart } from "../employees/components/employee_eng_chart/events_chart";

const Container = styled.div`
  margin: 24px 0px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  max-width: 854px;

  .barChart {
    padding: 32px 24px;
  }

  .title {
    margin-bottom: 24px;
    background: #f4f5f6;
    font-family: "Circular";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #4d4d4d;
    padding: 4px 16px;

    #numberValue {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      color: #00476f;
      margin-right: 32px;
    }
  }

  #charts {
    padding: 24px 36px 76px 8px;

    .chart {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  #event-summary {
    display: flex;
    flex-direction: row;
    padding: 24px 32px 18px;
    justify-content: space-between;

    > div {
      font-family: "Circular";
      font-style: normal;
      line-height: 26px;

      h4 {
        color: #4d4d4d;
        letter-spacing: 0.015em;
        font-size: 16px;
        font-weight: 700;
      }

      h2 {
        font-family: "Circular";
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.015em;
        color: #00476f;
      }
    }
  }
`;

export default function EventsCard(props) {
  const { dateFilter } = props;
  const defaultData = {
    projects: {
      applied: 0,
      matched: 0,
      completed: 0,
    },
    sessions: {
      applied: 0,
      matched: 0,
      completed: 0,
    },
    events: {
      led: 0,
      attended: 0,
      hours_donated: 0,
      value_donated: 0,
    },
    total_activity: 0,
  };
  const [dateQuery, setDateQuery] = useState("");
  const [dataURL, setDataURL] = useState(
    `/admin_pro/org_metrics/org_engagement_chart.json${dateQuery}`
  );
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setDataURL(`/admin_pro/org_metrics/org_engagement_chart.json${dateQuery}`);
  }, [dateQuery]);

  useEffect(() => {
    setDateQuery(dateFilter);
  }, [dateFilter]);

  useEffect(() => {
    fetch(dataURL)
      .then((response) => {
        return response.clone().json();
      })
      .then((response) => {
        setData(response);
      });
  }, [dataURL]);

  return (
    <Container>
      <div className="header">Events</div>
      <div id="event-summary">
        <div>
          <h4># of People Leading Events</h4>
          <h2>{data.events.led}</h2>
        </div>
        <div>
          <h4># of People Attending Events</h4>
          <h2>{data.events.attended}</h2>
        </div>
        <div>
          <h4>Hours Donated</h4>
          <h2>{data.events.hours_donated}</h2>
        </div>
        <div>
          <h4>Value Donated</h4>
          <h2>${data.events.value_donated}</h2>
        </div>
      </div>
      <div id="charts">
        <div>
          <EventsChart data={data} />
        </div>
      </div>
    </Container>
  );
}
