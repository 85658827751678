import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AllChart } from "./components/org_eng_chart/all_chart";

const Container = styled.div`
  margin: 24px 0px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  max-width: 854px;

  .barChart {
    padding: 32px 24px;
  }

  .title {
    margin-bottom: 24px;
    background: #f4f5f6;
    font-family: "Circular";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.015em;
    color: #4d4d4d;
    padding: 4px 16px;

    #numberValue {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      color: #00476f;
      margin-right: 32px;
    }
  }

  #charts {
    padding: 24px 32px 68px 24px;

    .chart {
      display: none;

      &.active {
        display: block;
      }
    }

    .legendSwitch {
      font-family: "Circular";
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #4d4d4d;
      margin-bottom: 32px;

      > div {
        border-bottom: 1px solid #e9eced;
        min-width: 82px;
        cursor: pointer;
        padding: 8px 32px 8px 16px;
        display: flex;
        align-items: center;
        color: #4d4d4d;

        &:hover {
          background-color: #f4f5f6;
        }

        &.active {
          border-bottom: 4px solid #007abe;
          color: #007abe;
          font-weight: bolder;
        }

        div {
          width: 16px;
          height: 8px;
          border-radius: 1px;
          margin-right: 8px;

          &#blue {
            background-color: #007abe;
          }

          &#yellow {
            background-color: #fec526;
          }

          &#green {
            background-color: #00b1af;
          }
        }
      }
    }

    #divider {
      margin: 24px 0px;
      width: 100%;
      border: 1px dashed #e9eced;
    }
  }
`;

export default function EmpEngagement(props) {
  const { dateFilter } = props;
  const defaultData = {
    projects: {
      applied: 0,
      matched: 0,
      completed: 0,
    },
    sessions: {
      applied: 0,
      matched: 0,
      completed: 0,
    },
    events: { led: 0, attended: 0 },
    total_activity: 0,
  };
  const [dateQuery, setDateQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [data, setData] = useState(defaultData);
  const [dataURL, setDataURL] = useState(
    `/admin_pro/org_metrics/org_engagement_chart.json${dateQuery}`
  );

  const showChart = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    setDataURL(`/admin_pro/org_metrics/org_engagement_chart.json${dateQuery}`);
  }, [dateQuery]);

  useEffect(() => {
    setDateQuery(dateFilter);
  }, [dateFilter]);

  useEffect(() => {
    fetch(dataURL)
      .then((response) => {
        return response.clone().json();
      })
      .then((response) => {
        setData(response);
      });
  }, [dataURL]);

  const isActive = (tabName) => (tabName === activeTab ? "active" : "");

  const total_activity = () => {
    switch (activeTab) {
      case "all":
        return data.total_activity;
      case "projects":
        return data.projects.applied;
      case "sessions":
        return data.sessions.applied;
      case "events":
        return data.events.led + data.events.attended;
      default:
        return data.total_activity;
    }
  };

  const activity_text = () => {
    if (total_activity() > 1) {
      return "Activities";
    } else {
      return "Activity";
    }
  };

  return (
    <Container>
      <div className="header">Organization Engagement</div>
      <div id="charts">
        <div className="title">
          <span id="numberValue">{total_activity()}</span>
          Total {activity_text()}
        </div>
        <div className="legendSwitch">
          <div className={isActive("all")} onClick={() => showChart("all")}>
            All
          </div>
          <div
            className={isActive("projects")}
            onClick={() => showChart("projects")}
          >
            <div id="blue"></div>Projects
          </div>
          <div
            className={isActive("sessions")}
            onClick={() => showChart("sessions")}
          >
            <div id="yellow"></div> Sessions
          </div>
        </div>
        <div>
          <AllChart chartName={activeTab} data={data} />
        </div>
      </div>
    </Container>
  );
}

EmpEngagement.defaultProps = {};

EmpEngagement.propTypes = {};
