$(window).on('load', function(){
  // Label toggle
  // Check all controls
  // and display label if they contain values
  $('input').each(function(i, obj) {
    displayLabel($(this));
  });

  // Input control
  $('input').on('input',function(e){
    displayLabel($(this));
  });

  // Set the dynamic dropdown
  $('select').each(function(index, value){
    if(!$(this).val()){
      $(this).addClass('unselected');
    }else{
      displayLabel($(this));
    }

    $(this).on({
      'change': function(){
                  if($(this).val()){
                    $(this).removeClass('unselected');
                  }else{
                    $(this).addClass('unselected');
                  }
                  displayLabel($(this));
                },
      'mouseenter': function(){
                $(this).removeClass('unselected');
              },
      'mouseleave': function(){
        if(!$(this).val()){
          $(this).addClass('unselected');
        }
      },
      'focus': function(){
                $(this).removeClass('unselected');
              }
    })
  });

  // Toggles label display
  function displayLabel(inputDom){
    var inputId = inputDom.attr('id');
    var labelName = inputDom.attr('placeholder');

    if(labelName == undefined){
      return ;
    }

    if(inputDom.hasClass('no-label')){
      return ;
    }

    if(inputDom.val().length){
      if(!inputDom.hasClass('label-visible')){
        var labelDom = $(`<label for='${inputId}' hidden class='dy-label'>${labelName}</label>`);
        inputDom.parent().prepend(labelDom);
        labelDom.fadeToggle("slow");
        inputDom.addClass('label-visible');
      }
    }else{
      var labelDom = inputDom.parent().find(`label[for=${inputId}]`);
      labelDom.fadeToggle("slow", function(){
        labelDom.remove();
      });
      inputDom.removeClass('label-visible');
    }
  }

  //-- Dropdowns --//
  $('.dropdown-input').on('input',function(){
    var inputDom = $(this);

    if(inputDom.val().trim().length > 2){
      var dropOptions = $('<div class="dropdown-options"></div>');
      if(!inputDom.hasClass('opened')){
        inputDom.addClass('opened');

        // Create dropdown options dom
        $(this).closest('.card-header').append(dropOptions);
      }

      // Get possible options and append to dropdown options
      var options = $(this).attr('data-options').split("|");
      var uniqOptions = [...new Set(options)];
      var displayOptions = new Array();
      uniqOptions.forEach(function(a){
        if (typeof(a) == 'string' && a.toLowerCase().indexOf(inputDom.val().trim().toLowerCase()) > -1) {
          if(displayOptions.length < 6){
            displayOptions.push(a);
          }
        }        
      });

      // Create the dropdown list
      displayOptions.forEach(element => {
        dropOptions.append(`<a class="dropdown-option sub-skill" data-skill="${element}">${element}</a>`);
      });
    }else{
      $('div.dropdown-options').remove();
      inputDom.removeClass('opened');
    }
  })

  //-- General dropdown input {focusin} --//
  $('.dropdown > input[type="text"]').on('focusin click', function(){
    renderDropdownOptions($(this));
  });

  function lowerSquash(str){
   return str.replace(/\s+/g, '-').toLowerCase();
  }

  // Render dropdown menu
  function renderDropdownOptions(dropDom){
    var inputDom = dropDom;

    // If options are already populated display options
    var dropOptions = inputDom.next('.dropdown-options');
    if(dropOptions.length){
      if(!dropOptions.is(':visible')){
        dropOptions.removeClass('hidden');
        dropOptions.slideDown(200);
      }
      return ;
    }

    dropOptions = $('<div class="dropdown-options hidden"></div>');

    // Set the select type
    dropOptions.attr('data-type', inputDom.parent().attr('data-type'));

    // Create dropdown options dom
    inputDom.closest('.dropdown').append(dropOptions);

    // Get possible options and append to dropdown options
    if(!inputDom.attr('data-options')){
      return ;
    }

    var options = inputDom.attr('data-options').split("|")

    // Create the dropdown list
    var optionType = inputDom.closest('div.dropdown').attr('data-type');
    var inputName = inputDom.attr('data-name');
    var inputValue = '';
    var inputLabel = '';
    options.forEach(element => {
      element = element.split(';');
      inputLabel = element[0];
      inputValue = element[1] == undefined ? inputLabel : element[1];

      var dropdownItem = $('<div class="dropdown-item"></div>');
      if(optionType == 'radioselect'){
        inputDom = `<input type="radio" id="di_${lowerSquash(inputLabel)}" name="${inputName}" value="${inputValue}" />`;
      }else{
        inputDom = `<input type="checkbox" id="di_${lowerSquash(inputLabel)}" name="${inputName}" value="${inputValue}" />`;
      }
      label = `<label for="di_${lowerSquash(inputLabel)}">${inputLabel}</label>`;
      dropdownItem.append(inputDom + label);
      dropOptions.append(dropdownItem);
    });
  }

  // Select default values
  function selectValues(inputDom){
    var values = inputDom.attr('data-value');
    values = values.split("|");
    inputDom.next('div.dropdown-options').find('input').each(function(index, value){
      if(values.indexOf($(this).val()) > -1){
        $(this).parent().click();
      }
    })
  }

  //-- dropdown input {focusout} --//
  $('.dropdown > input[type="text"]').on('focusout', function(){
    var inputDom = $(this);
    var dropOptions = inputDom.next('div.dropdown-options');

    if(dropOptions.is(':hover') == false){
      dropOptions.slideUp(200);
    }
  });

  //-- select option in dropdown --//
  $(document).on('click', '.dropdown-item', function(){
    var selectItem = $(this);
    selectOption(selectItem, selectItem.parent(), selectItem.find('input[type="checkbox"], input[type="radio"]'));
  });

  //-- select option in dropdown --//
  $(document).on('click', '.dropdown-item > label', function(){
    var selectItem = $(this);
    selectOption(selectItem.parent(), selectItem.parent().parent(), selectItem.parent().children('input[type="checkbox"], input[type="radio"]'));
  });

  // Select Option
  function selectOption(selectDom, parentDom, toggleDom){
    // If has active class, remove
    if(selectDom.hasClass('active')){
      toggleDom.prop('checked', false);
      selectDom.removeClass('active');
      return ;
    }
    // If this is a multiselect set the selectedItem to be active
    // else toggle between selected values
    if(parentDom.attr('data-type') != 'multiselect'){
      parentDom.find('div.dropdown-item').removeClass('active');
      parentDom.find('div.dropdown-item').each(function(index, value){
        $(this).children('input[type="checkbox"], input[type="radio"]').prop('checked', false);
      });
    }

    selectDom.toggleClass('active');
    toggleDom.prop('checked', selectDom.hasClass('active'));
    parentDom.parent().find('input[type="text"]').focus();
  }

  // Render all dropdowns
  $('.filter-control.dropdown > input[type="text"]').each(function(index, value) {
    renderDropdownOptions($(this));
    selectValues($(this));
  });
  
  // Render skill select
  $('.skill-select').on('change', function(){
    let selectedSkill = $(this).find('select')[0].value;
    let skillsInput = $(this).find('.skills-input')[0];
    let skillsArr = skillsInput.value.split("|");
    let skillsWrapper = $(this).find('.skills-wrapper')[0];

    skillsArr.push(selectedSkill);
    skillsArr = uniqueArr(skillsArr);
    skillsArr = removeEle(skillsArr, "");
    // set skills value
    skillsInput.value = skillsArr.join("|");

    // display skills
    skillsWrapper.innerHTML = skillsArr.map(skill => skillDom(skill)).join("");
  });

  $($('.skill-select')).on('click', '.skill-inner .del-skill', function(){
    let parentDom = $(this).parent().parent().parent();
    let valueSelected = $(this).attr('data-id');
    let skillsInput = parentDom.siblings('.skills-input')[0];
    let skillsWrapper = parentDom[0];
    let skillsArr = skillsInput.value.split("|");
    
    // remove skill from array
    skillsArr = removeEle(skillsArr, valueSelected);
    skillsInput.value = skillsArr.join("|");
    skillsWrapper.innerHTML = skillsArr.map(skill => skillDom(skill)).join("");
  })
  
  function skillDom(skillStr){
    return `<div class="skill-outer"><div class="skill-inner">${skillStr}<span class="del-skill" data-id="${skillStr}">x</span></div></div>`; 
  }
  
  function uniqueArr(arr){
    return arr.filter(function(item, pos) {
      return arr.indexOf(item) == pos;
    })
  }
  
  function removeEle(arr, valueSelected){
    return jQuery.grep(arr, function(value) {
      return value != valueSelected;
    });
  }
  
  function setSkills(){
    $('.skill-select').each(function(index, value){
      let skillsInput = $(this).find('.skills-input')[0];
      let skillsWrapper = $(this).find('.skills-wrapper')[0];
      let skillsArr = [];
      if(skillsInput.value.length > 0){
        skillsArr = skillsInput.value.split("|");
      }
      skillsWrapper.innerHTML = skillsArr.map(skill => skillDom(skill)).join("");
    })
  }
  
  setSkills();
  
  // Form ux
  $('.icon-edit').click(function(){
    let formId = $(this).attr('data-form');
    setFormStatus(formId, false);
    $(this).hide();
  });

  function setFormStatus(formId, readonly){
    let formSelector = `form.${formId}`;
    $(`${formSelector} input[type="text"], ${formSelector} input[type="email"], ${formSelector} textarea`).attr('readonly', readonly);
    $(`${formSelector} select`).attr("disabled", readonly);
    if(readonly){
      $(`${formSelector} input[type="submit"]`).hide();
    }else{
      $(`${formSelector} input[type="submit"]`).show();
    }
  }
});