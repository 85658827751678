import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DropdownCaret } from "../../../../../../../modules/components/SvgIcons";
import PropTypes from "prop-types";
import moment from "moment";
import { DateSelector } from "@taprootfoundation/tplus-ui-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
  justify-content: center;
  position: relative;

  #dateLabel {
    display: flex;
    flex-direction: row;
    font-family: "Circular";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #007abe;
    cursor: pointer;

    p {
      margin-right: 16px;
    }
  }

  #dateOptions {
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
    min-width: 193px;
    position: absolute;
    top: 40px;
    right: 0;
    cursor: pointer;
    border: 3px solid #ffffff;

    div {
      padding: 8px 24px;
      text-align: end;
      font-family: "Circular";
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.015em;
      color: #4d4d4d;

      &:hover {
        background-color: #ecf1f4;
      }

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }
    }
  }
`;

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function DateDropdown(props) {
  const wrapperRef = useRef(null);
  const { currRange, currDateFrom, currDateTo, onDateChange } = props;
  const [rangeLabel, setRangeLabel] = useState("All Time");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showCustom, setShowCustom] = useState(false);
  useOutsideAlerter(wrapperRef, () => setShowOption(false));

  const [showOption, setShowOption] = useState(false);

  const dateOptions = [
    ["This Week", "this_week"],
    ["This Month", "this_month"],
    ["This Year", "this_year"],
    ["Last 7 days", "last_7_days"],
    ["Last Month", "last_month"],
    ["Last Year", "last_year"],
    ["Custom", "custom"],
    ["All Time", "all"],
  ];

  const setDate = (range) => {
    const fromLabel = dateInterval(range[1])[0];
    const toLabel = dateInterval(range[1])[1];
    if (range[1] === "all") {
      setRangeLabel("All Time");
    } else if (range[1] === "custom") {
      return;
    } else {
      setRangeLabel(
        `${fromLabel.format("MMM DD YYYY")} - ${toLabel.format("MMM DD YYYY")}`
      );
    }
    setShowOption(false);
    onDateChange({
      range: range[1],
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  };

  const rows = dateOptions.map((x) => {
    return (
      <div key={x[1]} data-id={x[1]} onClick={() => setDate(x)}>
        {x[0]}
      </div>
    );
  });

  const dateInterval = (range) => {
    switch (range) {
      case "this_week":
        return [moment().startOf("week"), moment().endOf("week")];
      case "this_month":
        return [moment().startOf("month"), moment().endOf("month")];
      case "this_year":
        return [moment().startOf("year"), moment().endOf("year")];
      case "last_7_days":
        return [moment().subtract(7, "days"), moment()];
      case "last_month":
        return [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ];
      case "last_year":
        return [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ];
      case "all":
        return [null, null];
      case "custom":
        setShowOption(false);
        setShowCustom(true);
      default:
        return [null, null];
    }
  };

  const handleOptionClick = (e) => {
    setShowOption(!showOption);
    setShowCustom(false);
  };

  const handleOnDateChange = (date) => {
    setDateFrom(date.from);
    setDateTo(date.to);
    setRangeLabel(
      `${moment(date.from).format("MMM DD YYYY")} - ${moment(date.to).format(
        "MMM DD YYYY"
      )}`
    );
    setShowCustom(false);
    onDateChange({
      range: "custom",
      dateFrom: date.from,
      dateTo: date.to,
    });
  };

  const handleOnDateCancel = () => {
    setShowCustom(false);
  };

  return (
    <Container ref={wrapperRef}>
      <div id="dateLabel" onClick={handleOptionClick}>
        <p>{rangeLabel}</p>
        <DropdownCaret />
      </div>
      {showOption && <div id="dateOptions">{rows}</div>}
      {showCustom && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            right: "0px",
            zIndex: "1000",
          }}
        >
          <DateSelector
            from={dateFrom}
            to={dateTo}
            onDateChange={handleOnDateChange}
            onDateCancel={handleOnDateCancel}
          />
        </div>
      )}
    </Container>
  );
}

DateDropdown.defaultProps = {
  onDateChange: () => {},
};

DateDropdown.propTypes = {
  onDateChange: PropTypes.func,
};
