import React, { useEffect, useState } from "react";
import c3 from "c3";

export const AllChart = (props) => {
  const { chartName, data } = props;

  const maxData = () => {
    const values = [
      data.projects.applied,
      data.projects.matched,
      data.projects.completed,
      data.sessions.applied,
      data.sessions.matched,
      data.sessions.completed,
      data.events.led,
      data.events.attended,
    ];

    return Math.max(...values);
  };

  const defaultProps = {
    data: {
      x: "x",
      columns: [
        [
          "x",
          `Applied  -  ${data.projects.applied + data.sessions.applied}`,
          `Matched  -  ${data.projects.matched + data.sessions.matched}`,
          `Completed  -  ${data.projects.completed + data.sessions.completed}`,
        ],
        [
          "Projects",
          data.projects.applied,
          data.projects.matched,
          data.projects.completed,
        ],
        [
          "Sessions",
          data.sessions.applied,
          data.sessions.matched,
          data.sessions.completed,
        ],
      ],
      type: "bar",
    },
    size: {
      height: 320,
    },
    tooltip: {
      grouped: false,
      position: function (data, width, height, element) {
        var top = d3.mouse(element)[1] - 47;
        var left = d3.mouse(element)[0] + 17;
        return { top: top, left: left };
      },
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        return "<div>" + d[0].value + " " + d[0].name + "</div>";
      },
    },
    color: {
      pattern: ["#007ABE", "#FEC526"],
    },
    bar: {
      width: 32,
    },
    legend: {
      show: false,
    },
    grid: {
      y: {
        show: true,
      },
    },
    padding: {
      left: 100,
    },
    axis: {
      rotated: true,
      x: {
        type: "category",
      },
      y: {
        tick: {
          format: function (d) {
            return parseInt(d) == d ? d : null;
          },
        },
        max: maxData(),
      },
    },
  };

  const defaultEventProps = {
    ...defaultProps,
    size: {
      height: 140,
    },
    data: {
      x: "x",
      columns: [
        [
          "x",
          `Led  -  ${data.events.led}`,
          `Attended  -  ${data.events.attended}`,
        ],
        ["Events", data.events.led, data.events.attended],
      ],
      type: "bar",
    },
    color: {
      pattern: ["#008583"],
    },
    axis: { ...defaultProps.axis, y: { ...defaultProps.axis.y, show: true } },
  };

  const [chartProps, setChartProps] = useState(defaultProps);
  const [eventProps, setEventProps] = useState(defaultEventProps);

  const generateId = () =>
    (Math.random() + 1).toString(36).substring(5, 20).replace(/[0-9]/g, "");

  const chart1 = generateId();
  const chart2 = generateId();

  useEffect(() => {
    c3.generate({ ...chartProps, bindto: `#${chart1}` });
  }, [chartProps]);

  useEffect(() => {
    setChartProps({
      ...chartProps,
      data: {
        x: "x",
        columns: [
          [
            "x",
            `Applied  -  ${data.projects.applied + data.sessions.applied}`,
            `Matched  -  ${data.projects.matched + data.sessions.matched}`,
            `Completed  -  ${
              data.projects.completed + data.sessions.completed
            }`,
          ],
          [
            "Projects",
            data.projects.applied,
            data.projects.matched,
            data.projects.completed,
          ],
          [
            "Sessions",
            data.sessions.applied,
            data.sessions.matched,
            data.sessions.completed,
          ],
        ],
        type: "bar",
      },
      axis: {
        ...chartProps.axis,
        y: {
          ...chartProps.axis.y,
          max: maxData(),
        },
      },
    });
  }, [data]);

  const setAllChart = () => {
    var tempChart = {
      ...defaultProps,
      size: {
        height: 320,
      },
      axis: {
        ...defaultProps.axis,
        y: {
          ...defaultProps.axis.y,
        },
      },
    };
    setChartProps(tempChart);
  };

  const setProjectChart = () => {
    var tempChart = {
      ...chartProps,
      size: {
        height: 320,
      },
      axis: {
        ...chartProps.axis,
        y: {
          ...chartProps.axis.y,
          show: true,
        },
      },
      data: {
        ...chartProps.data,
        columns: [
          [
            "x",
            `Applied  -  ${data.projects.applied}`,
            `Matched  -  ${data.projects.matched}`,
            `Completed  -  ${data.projects.completed}`,
          ],
          [
            "Projects",
            data.projects.applied,
            data.projects.matched,
            data.projects.completed,
          ],
        ],
      },
      color: {
        pattern: ["#007ABE"],
      },
    };

    setChartProps(tempChart);
  };

  const setSessionChart = () => {
    var tempChart = {
      ...chartProps,
      size: {
        height: 320,
      },
      axis: {
        ...chartProps.axis,
        y: {
          ...chartProps.axis.y,
          show: true,
        },
      },
      data: {
        ...chartProps.data,
        columns: [
          [
            "x",
            `Applied  -  ${data.sessions.applied}`,
            `Matched  -  ${data.sessions.matched}`,
            `Completed  -  ${data.sessions.completed}`,
          ],
          [
            "Projects",
            data.sessions.applied,
            data.sessions.matched,
            data.sessions.completed,
          ],
        ],
      },
      color: {
        pattern: ["#FEC526"],
      },
    };

    setChartProps(tempChart);
  };

  useEffect(() => {
    switch (chartName) {
      case "all":
        setAllChart();
        break;
      case "projects":
        setProjectChart();
        break;
      case "sessions":
        setSessionChart();
        break;
      default:
        break;
    }
  }, [chartName]);

  return (
    <div>
      <div id={chart1} />
    </div>
  );
};
