import React, { useEffect, useState } from "react";
import c3 from "c3";

export const EventsChart = (props) => {
  const { data } = props;

  const maxData = () => {
    const values = [
      data.projects.applied,
      data.projects.matched,
      data.projects.completed,
      data.sessions.applied,
      data.sessions.matched,
      data.sessions.completed,
      data.events.led,
      data.events.attended,
    ];

    return Math.max(...values);
  };

  const defaultProps = {
    tooltip: {
      grouped: false,
      position: function (data, width, height, element) {
        var top = d3.mouse(element)[1] - 47;
        var left = d3.mouse(element)[0] + 17;
        return { top: top, left: left };
      },
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        return "<div>" + d[0].value + " " + d[0].name + "</div>";
      },
    },
    bar: {
      width: 32,
    },
    legend: {
      show: false,
    },
    grid: {
      y: {
        show: true,
      },
    },
    padding: {
      left: 100,
    },
    axis: {
      rotated: true,
      x: {
        type: "category",
      },
      y: {
        tick: {
          format: function (d) {
            return parseInt(d) == d ? d : null;
          },
        },
        max: maxData(),
      },
    },
  };

  const defaultEventProps = {
    ...defaultProps,
    size: {
      height: 140,
    },
    data: {
      x: "x",
      columns: [
        [
          "x",
          `Led  -  ${data.events.led}`,
          `Attended  -  ${data.events.attended}`,
        ],
        ["Events", data.events.led, data.events.attended],
      ],
      type: "bar",
    },
    color: {
      pattern: ["#008583"],
    },
  };

  const [eventProps, setEventProps] = useState(defaultEventProps);

  const generateId = () =>
    (Math.random() + 1).toString(36).substring(5, 20).replace(/[0-9]/g, "");

  const chart2 = generateId();

  useEffect(() => {
    c3.generate({ ...eventProps, bindto: `#${chart2}` });
  }, [eventProps]);

  useEffect(() => {
    setEventProps({
      ...eventProps,
      data: {
        x: "x",
        columns: [
          [
            "x",
            `Led  -  ${data.events.led}`,
            `Attended  -  ${data.events.attended}`,
          ],
          ["Events", data.events.led, data.events.attended],
        ],
        type: "bar",
      },
      axis: {
        ...eventProps.axis,
        y: {
          ...eventProps.axis.y,
          max: maxData(),
        },
      },
    });
  }, [data]);

  return (
    <div>
      <div id={chart2} />
    </div>
  );
};
