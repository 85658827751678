/*
  Adds Tooltip script to the page
  
  Dependencies:
   - jquery
  
  Usage:
  .tool-tip
    = embedded_svg('cd-assets/svg/icon-info.svg', {class: 'blue svg-icon'})
      .info
        p Valid urls starts with http:// or https:// e.g. https://www.website-name.org
 */
 
$(document).ready(()=> {
  // Tooltip script
  $("div.tool-tip").mouseenter(function (event) {
    $("div.tool-tip > .info").css("top", event.pageY - 100);
    $("div.tool-tip > .info").css("left", event.pageX - 50);
    $("div.tool-tip > .info").css("font-size", "12px");
  })
});
