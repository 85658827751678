$(document).ready(function(){
  $('.view-toggle').on('click', function() {
    $(this).toggleClass('visible');
    var dataId = $(this).attr('data-input');
    if($(this).hasClass('visible')){
      $(`#command_${dataId}`).attr('type','text');
    }else{
      $(`#command_${dataId}`).attr('type','password');
    }
  })
})