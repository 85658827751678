import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Employees from "./components/employees";
import Organizations from "./components/organizations";
import DateDropdown from "./components/date_dropdown";

const Container = styled.div``;

const Header = styled.div`
  padding: 24px 42px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(174, 174, 174, 0.5);
  margin-bottom: 40px;

  .title {
    font-family: "Circular";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #4d4d4d;
  }

  button {
    background: #007abe;
    border-radius: 2px;
    color: #ffffff;
    font-family: "Clarke";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.015em;
    border: none;
    min-width: 160px;
    min-height: 40px;
    cursor: pointer;

    &:hover {
      background: #00476f;
    }
  }

  .menuOptions {
    display: flex;
    flex-direction: row;
  }
`;

function IndexPage(props) {
  const { showEmployee } = props;
  const [dateFilter, setDateFilter] = useState("");

  const setDate = (e) => {
    const query = [`range=${e.range}`];

    if (e.range === "custom") {
      if (e.dateFrom) {
        query.push(`start_date=${e.dateFrom}`);
      }

      if (e.dateTo) {
        query.push(`end_date=${e.dateTo}`);
      }
    }

    setDateFilter(`?${query.join("&")}`);
  };

  return (
    <Container>
      <Header>
        <div className="title">Dashboard</div>
        <div className="menuOptions">
          <DateDropdown onDateChange={(e) => setDate(e)} />
        </div>
      </Header>

      {showEmployee && <Employees dateFilter={dateFilter} />}
      <Organizations dateFilter={dateFilter} />
    </Container>
  );
}

if (document.querySelector("[data-js=dashboard-index]")) {
  ReactDOM.render(
    <IndexPage showEmployee={true} />,
    document.querySelector("[data-js=dashboard-index]")
  );
}

// dashboard-index-org is for grantmakers
if (document.querySelector("[data-js=dashboard-index-org]")) {
  ReactDOM.render(
    <IndexPage showEmployee={false} />,
    document.querySelector("[data-js=dashboard-index-org]")
  );
}
