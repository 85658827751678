class MultiMessage {
  constructor(messages) {
    this.messages = messages;
  }

  template() {
    let $msgList = $("<div>");
    for (let msg of this.messages) {
      $msgList.append($("<div>").text(msg.template()));
    }
    return $msgList;
  }
}

class Message {
  static normalize(error) {
    let messages;
    switch ($.type(error)) {
      case "object":
        messages = Object.keys(error).map((e) => { return new Message(error[e], e) });
        break;
      case "array":
        messages = error.map((e) => { return new Message(e) });
        break;
      default:
        messages = [new Message(error)];
        break;
    }

    return messages;
  }

  static template(errors) {
    let messages = Message.normalize(errors), content;

    if (messages.length > 1) {
      content = new MultiMessage(messages);
    } else {
      content = messages[0];
    }
    return content.template();
  }

  constructor(messages, key="") {
    this.message = [].concat(messages).join(", ");
    this.key = key;
  }

  template() {
    if (this.looksBetterWithKey()) {
      let prettyKey = this.key.charAt(0).toUpperCase() + this.key.slice(1);
      return [prettyKey, this.message].join(" ");
    } else {
      return this.message;
    }
  }

  looksBetterWithKey() {
    return ["can't be blank"].includes(this.message);
  }
}

export default class Flash {
  static add(klass, content, link) {
    let $msg = $("<div>");
    $msg.addClass("flash-message");
    $msg.addClass(klass);
    $msg.html(Message.template(content));
    $(".flash-messages").append($msg);
    if (link) {
      $msg.on("click", () => {
        window.location = link;
      });
    } else {
      $msg.on("click", () => {
        $msg.remove();
      });
    }
    setTimeout(() => {
      $msg.remove();
    }, 3000);
  }

  static addError(string, link) {
    this.add("flash-alert", string, link);
  }

  static addMessage(string, link) {
    this.add("flash-notice", string, link);
  }
}
