import React, { useEffect } from "react";
import styled from "styled-components";
import EmployeeChart from "./employee_chart";
import EmpEngagement from "./emp_engagement";
import EventsCard from "./events_card";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 40px;

  #title {
    font-family: "circular";
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.015em;
    color: #4d4d4d;
  }

  .header {
    padding: 18px 24px;
    font-family: "Circular";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #4d4d4d;
    border-bottom: 1px solid #f4f5f6;
  }
`;

export default function Employees(props) {
  const { dateFilter } = props;

  return (
    <Container>
      <div id="title">Employees</div>
      <EmployeeChart dateFilter={dateFilter} />
      <EmpEngagement dateFilter={dateFilter} />
      <EventsCard dateFilter={dateFilter} />
    </Container>
  );
}

Employees.defaultProps = {};

Employees.propTypes = {};
