import "script-loader!jquery.cookie";
import { Foundation } from "foundation-sites/js/foundation.core";
import { Reveal } from "foundation-sites/js/foundation.reveal";
import { OffCanvas } from "foundation-sites/js/foundation.offcanvas";
import "chartkick";
import "chart.js";
import Flash from "../lib/flash";
import "../lib/single_submit";
import "../lib/password_toggle";
import "../lib/form_validator";
import "../lib/form_ux";
import TPlusDropdown from "../lib/tplus_dropdown";
import CharCounter from "../lib/char_counter";
import "../lib/tooltip";
import "../lib/url_validator";
import "../views/corporate";
require("@rails/activestorage").start();

class Corporate {
  init(doc) {
    this.initFoundation(doc);
    this.initProjectItem(doc);
    this.initCharCounter(doc);
  }

  initFoundation(doc) {
    Foundation.addToJquery($);
    Foundation.plugin(Reveal, "Reveal");
    Foundation.plugin(OffCanvas, "OffCanvas");
    jQuery(doc).foundation();
  }

  addFlash(attrs) {
    if (attrs.kind === "alert") {
      Flash.addError(attrs.text);
    } else {
      Flash.addMessage(attrs.text);
    }
  }

  initProjectItem(doc) {
    $(".title-desc").click(function () {
      var btn = $(this).find("#engagement-name");
      window.location = btn.attr("href");
    });
  }

  initDropdown(doc) {
    new TPlusDropdown().init(doc);
  }

  initCharCounter(doc) {
    CharCounter.init();
  }
}

window.corporate = new Corporate();
window.corporate.init(document);
