/*
  TPlus Character Counter
  This counts the number of characters in a textbox.
  This functionality prompts the user in entering the right amount of characters.
  
  Dependencies:
   - jquery
  
  Usage:
  <input type='text' data-counter='enabled' data-min-chars='200' data-max-chars='400'/>
 */
export default class CharCounter{
  static init = () => {
    $('*[data-counter="enabled"]').on('input', function(){
      let inputEle = $(this);
      let min = parseInt(inputEle.attr('data-min-chars'));
      let max = parseInt(inputEle.attr('data-max-chars'));
      if(isNaN(max) && isNaN(min)){ return null; }

      let currentLength = inputEle.val().split(' ').length;

      let counterEle = inputEle.next('p.char-counter');
      if(counterEle.length === 0){
        counterEle = $("<p></p>");
        counterEle.addClass('char-counter');
        inputEle.after(counterEle);
      }
      
      if(currentLength === 0){
        counterEle.remove();
        return null;
      }

      CharCounter.render(counterEle, min, max, currentLength);
    })
  }
  
  static render = (_el, min, max, len) => {
    let suffix = 'words';
    if(len > max){
      suffix = `words (maximum ${max})`
      _el.addClass('warning');
    }else if(len <= max && len >= min){
      _el.removeClass('warning')
    }else{
      suffix = `words (minimum ${min})`
      _el.addClass('warning');
    }

    _el.text(`${len}/${max} ${suffix}`);
  }
}