import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LineChart } from "react-chartkick";
import "chart.js";
import ValueTab from "./components/employee_chart/value_tab";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  max-width: 854px;
  margin-top: 34px;

  #tabs {
    display: flex;

    > div {
      padding: 22px 32px;
      flex: 1;
      border-top: 6px solid #f8f8f8;
      background-color: #f8f8f8;
      cursor: pointer;

      &:active,
      &:hover {
        border-top: 6px solid #f0f0f0;
        background-color: #f0f0f0;
      }
      &.active {
        border-top: 6px solid #007abe;
        background-color: #ffffff;
      }

      .title {
        font-family: "circular";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
        color: #4d4d4d;
      }

      .value {
        font-family: "Circular";
        font-style: normal;
        font-weight: bolder;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.015em;
        color: #00476f;
        margin-top: 8px;

        &-na {
          color: #ced4d7;
        }
      }
    }
  }

  #charts {
    padding: 24px 32px 68px 24px;

    .chart {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

export default function EmployeeChart(props) {
  const { dateFilter } = props;
  const [activeTab, setActiveTab] = useState("accountCreated");
  const [dateQuery, setDateQuery] = useState("");
  const showChart = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    setDateQuery(dateFilter);
  }, [dateFilter]);

  const isActive = (tabName) => (tabName === activeTab ? "active" : "");

  return (
    <Container>
      <div id="tabs">
        <div
          onClick={() => showChart("accountCreated")}
          className={"tab " + isActive("accountCreated")}
        >
          <ValueTab
            dataIndex="accounts_created"
            label="Accounts Created"
            dataURL={`/admin_pro/employee_metrics/accounts_created.json${dateQuery}`}
          />
        </div>
        <div
          onClick={() => showChart("engagedAccounts")}
          className={"tab " + isActive("engagedAccounts")}
        >
          <ValueTab
            dataIndex="engaged_accounts"
            label="Engaged Accounts"
            dataURL={`/admin_pro/employee_metrics/engaged_accounts.json${dateQuery}`}
          />
        </div>
        <div
          onClick={() => showChart("hoursDonated")}
          className={"tab " + isActive("hoursDonated")}
        >
          <ValueTab
            dataIndex="hours_donated"
            label="Hours Donated"
            dataURL={`/admin_pro/employee_metrics/hours_donated.json${dateQuery}`}
          />
        </div>
        <div
          onClick={() => showChart("valueDonated")}
          className={"tab " + isActive("valueDonated")}
        >
          <ValueTab
            prefix="$"
            dataIndex="value_donated"
            label="Value Donated"
            dataURL={`/admin_pro/employee_metrics/value_donated.json${dateQuery}`}
            abbr={true}
          />
        </div>
      </div>
      <div id="charts">
        <div
          id="accountCreatedChart"
          className={"chart " + isActive("accountCreated")}
        >
          <LineChart
            data={`/admin_pro/employee_metrics/accounts_created_chart.json${dateQuery}`}
            curve={false}
          />
        </div>
        <div
          id="engagedAccountsChart"
          className={"chart " + isActive("engagedAccounts")}
        >
          <LineChart
            data={`/admin_pro/employee_metrics/engaged_accounts_chart.json${dateQuery}`}
            curve={false}
          />
        </div>
        <div
          id="hoursDonatedChart"
          className={"chart " + isActive("hoursDonated")}
        >
          <LineChart
            data={`/admin_pro/employee_metrics/hours_donated_chart.json${dateQuery}`}
            curve={false}
          />
        </div>
        <div
          id="valueDonatedChart"
          className={"chart " + isActive("valueDonated")}
        >
          <LineChart
            data={`/admin_pro/employee_metrics/value_donated_chart.json${dateQuery}`}
            curve={false}
          />
        </div>
      </div>
    </Container>
  );
}

EmployeeChart.defaultProps = {};

EmployeeChart.propTypes = {};
