$(document).ready(function(){
  var inputSelectors = 'form.validator input[type="text"], form.validator input[type="password"], form.validator input[type="email"]';

  $('form.validator input[type="checkbox"]').on('change', function(){
    $(this).addClass('to-validate');
    validateInput($(this), null);
  });

  $(inputSelectors).on('focusout',function(){
    $(this).addClass('to-validate');
    validateInput($(this), null);
  });

  $('form.validator').submit(function(event){
    event.preventDefault();
    validateForm($(this), event)
  });

  function validateInput(inputDom){
    var form = inputDom.closest('form');
    validateForm(form, null);
  }

  function validateForm(form, event){
    var formAction = form.attr('action');
    formAction = updateQueryStringParameter(formAction, 'validate', 'true');

    // perform ajax to the controller data has to be submitted
    $.ajax({
      url: formAction,
      method: "POST",
      dataType: "json",
      accepts: {
        text: "application/json"
      },
      data: form.serialize(),
      formObj: form
    }).done(function(res) {
      if(res.valid){
        removeAllErrors();
        if(event != null){
          form.unbind('submit').submit();
        }
      }else{
        if(event != null){
          event.preventDefault();
        }
        displayResponse(res, event);
      }
    });

    return false;
  }

  // adds query parameters to url
  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  // Displays the right validation messages
  function displayResponse(response, event){
    if(response.valid == false){
      displayValidationErrors(response.errors, event);
    }else{
      removeAllErrors();
    }
  }

  // reset all error messages
  function removeAllErrors(){  
    $('label').removeClass('error');
    $('input[type="checkbox"]').removeClass('error-input');
    $('div.error').each(function(index){
      var ele = $(this);
      if(ele.hasClass('input')){
        ele.removeClass('error');
      }else{
        ele.remove();
      }
    });
    $('ul.error').remove();
    $('small.error').remove();
  }

  // Display validation messages
  function displayValidationErrors(errors, event){
    removeAllErrors();

    // Render error messages
    for (let key in errors) {
      let error = errors[key];
      var inputDom = $(`#command_${key}`);

      // if event is null
      // validate single input
      // else validate all inputs
      if(event == null){
        if(!inputDom.hasClass('to-validate')){
          continue;
        }
      }

      var inputParent = inputDom.parent();

      // If element is checkbox,
      // change the label class to reflect the error
      // else display the error in a div below
      // the input control
      if(inputDom.attr('type') == 'checkbox'){
        inputParent.find('label').addClass('error');
        inputDom.addClass('error-input');
      }else{
        if(error.length > 1){
          errorDom = renderErrorList(error);
        }else{
          errorDom = renderErrorDiv(error);
        }
        inputParent.append(errorDom);
      }
    }
  }

  // Render error div
  function renderErrorDiv(error){
    return $(`<div class="error"></div>`).html(error);
  }

  // Render error list
  function renderErrorList(errorList){
    var errorEle = $('<ul class="error"></ul>');
    errorList.forEach(error => {
      errorEle.append(`<li>${error}</li>`)
    });
    return errorEle;
  }
});