/*
 Taproot Plus Dropdown turns the
 <select> element into a custom dropdown
 
 Dependencies:
   - jquery
   - tplus_dropdown.scss
 */
export default class TPlusDropdown {

  constructor() {
    this._this = this;
  }

  // Starts the dropdown
  init = (document) => {
    let _this = this._this;

    $('select').each(function (index) {
      let currentEle = $(this);
      let selectName = currentEle.attr('name');
      let dropDown = $('<div class="tp_dropdown"></div>');
      let hiddenInput = $(`<input type="hidden" name="${selectName}"/>`)
      // set options
      let options = $('<div class="options"></div>');
      options.hover(function () {
        var oldScrollPos = $(window).scrollTop();

        $(window).on('scroll.scrolldisabler', function (event) {
          $(window).scrollTop(oldScrollPos);
          event.preventDefault();
        });
      }, function () {
        $(window).off('scroll.scrolldisabler');
      });


      // set selected option dom
      let selected = $('<div class="selected"></div>');
      selected.click(function () {
        options.toggle();
      });

      $(document).mouseup(function(e) {
        // if the target of the click isn't the container nor a descendant of the container
        if (!options.is(e.target) && options.has(e.target).length === 0)
        {
          options.hide();
        }
      });

      selected.attr('id', currentEle.attr('id'));
      selected.addClass(currentEle[0].className);
      hiddenInput.attr('placeholder', currentEle.attr('placeholder'));
      hiddenInput.attr('id', `hi-${currentEle.attr('id')}`);
      
      dropDown.prepend(hiddenInput);
      dropDown.append(selected);
      dropDown.append(options);
      currentEle.after(dropDown);

      // Get the list of options
      currentEle.children().each(function () {
        _this.createOptions($(this), options, selected, hiddenInput);
      });
      currentEle.remove();
    });
  }

  createOptions(_el, options, selected, hiddenInput) {
    let _this = this._this;

    // create option elements
    let _optEl = $('<div></div>');
    _optEl.addClass(_el[0].localName);
    _optEl.text(_el[0].label);
    _optEl.attr('value', _el[0].value);

    if (_el[0].selected) {
      _optEl.addClass('active');
      selected.text(_el[0].label);
      hiddenInput.val(_el[0].value);
    }
    
    if(_el[0].localName === 'option'){
      _optEl.click(function () {
        $(this).parent().children().removeClass('active');
        $(this).addClass('active');
        selected.text($(this).text());
        hiddenInput.val($(this).attr('value')).trigger('input');
        options.hide();
      });
    }

    options.append(_optEl);

    _el.children().each(function () {
      _this.createOptions($(this), options, selected, hiddenInput);
    });
  }
}