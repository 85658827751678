/*
  Adds Url Validator script 
  
  Dependencies:
   - jquery
  
  Usage:
    <input class="validate_url" type="text">
  
 */

$(document).ready(()=> {
  // on submit check the url
  $('form').on('submit', function (e) {
    if (typeof $('.validate_url').val() === "string"){
      $('.validate_url').each(function(){
        let websiteUrl = $(this).val();
        if (websiteUrl.trim() !== "") {
          const pattern = /^((http|https|ftp):\/\/)/;
          if (!pattern.test(websiteUrl)) {
            $(this).val("http://" + websiteUrl);
          }
        }
      });
    }
  })
});
