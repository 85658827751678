import React, { useEffect, useState } from "react";
import "chart.js";
import { abbreviateNumber } from "../../../../../../../../../modules/shared/utils";
import PropTypes from "prop-types";

export default function ValueTab(props) {
  const { dataIndex, prefix, label, dataURL, abbr } = props;
  const [value, setValue] = useState("NA");
  const inAbbr = abbr ? abbr : false;

  useEffect(() => {
    fetch(dataURL)
      .then((response) => {
        return response.clone().json();
      })
      .then((data) => {
        setValue(data[dataIndex]);
      });
  }, [dataURL]);

  return (
    <div>
      <div className="title">{label}</div>
      {abbr ? (
        <div className="value">{abbreviateNumber(prefix, parseInt(value))}</div>
      ) : (
        <div className="value">
          {prefix}
          {value}
        </div>
      )}
    </div>
  );
}

ValueTab.defaultProps = {
  dataIndex: "",
  prefix: "",
};

ValueTab.propTypes = {
  dataIndex: PropTypes.string,
  prefix: PropTypes.string,
};
