import Moment from "moment";

export const holidays = [
  {
    text: "Memorial day",
    time: Moment("2016-05-30"),
  },
  {
    text: "Independence day",
    time: Moment("2016-07-04"),
  },
];

export const toggleTime = (time, timeSlots) => {
  let thisMoment = Moment();
  let adding = !time.selected;
  // const maxTimeslots = 5;

  if (adding) {
    if (time.time <= thisMoment) {
      Flash.addError("Time is in the past");
    } else if (time.availability.count === "0") {
      Flash.addError("No availability at that time");
    } else if (time.schedulable && !time.conflict) {
      time.selected = true;
      timeSlots.push(time.time);
    }
  } else {
    time.selected = false;
    timeSlots = timeSlots.filter((i) => {
      return !time.time.isSame(i);
    });
  }
  return timeSlots.sort((a, b) => {
    return a - b;
  });
};

export const buildDays = (
  timeSlots,
  basetime,
  availabilities,
  bookedTimes,
  holidays
) => {
  let thisMoment = Moment();
  let days = [[], [], [], [], []];
  let isoWeekOffset = 1;
  for (let d = 0; d < days.length; d++) {
    for (let i = 0; i <= 48; i++) {
      let time = basetime
        .clone()
        .add(d, "day")
        .add(i * 0.5, "hour");
      let scheduleHour = time.hour();
      let endtime = time.clone().add(1, "hour");
      let text = "";

      let availability = availabilities[(d + isoWeekOffset) * 24 + i];
      let conflict = bookedTimes.some((e) => {
        let bookedTime = Moment(e);
        return bookedTime >= time && bookedTime < endtime;
      });
      let schedulable = scheduleHour >= 9 && scheduleHour < 18;
      let now = thisMoment >= time && thisMoment < endtime;
      let past = thisMoment > time;
      let selected = timeSlots.some((e) => {
        return e.isSame(time);
      });
      let holiday = holidays.filter((e) => {
        return e.time.isSame(time, "day");
      })[0];

      if (now) {
        text = "Now";
      }

      if (conflict) {
        text = "Busy";
      }

      if (schedulable) {
        days[d].push({
          time,
          availability,
          schedulable,
          conflict,
          selected,
          text,
          holiday,
          past,
        });
      }
    }
  }
  return days;
};

export const abbreviateNumber = (prefix, number) => {
  if (isNaN(number)) return "-";

  const SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"];
  const sign = number < 0 ? "-" : "";
  const absNumber = Math.abs(number);
  const tier = (Math.log10(absNumber) / 3) | 0;

  // if zero, we don't need a postfix
  if (tier == 0) return `${prefix}${absNumber}`;

  // get postfix and determine scale
  const postfix = SI_POSTFIXES[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = absNumber / scale;
  const floored = Math.floor(scaled * 10) / 10;

  // format number and add postfix as suffix
  let str = floored.toFixed(1);

  // remove '.0' case
  str = /\.0$/.test(str) ? str.substr(0, str.length - 2) : str;
  return `${prefix}${sign}${str}${postfix}`;
};
