$(document).ready(function(){
  //On click of an anchor link
  $('a').click(function(e){
    // excluded links
    var excluded_links = ['#','','javascript:void(0)','javascript:;'];
    var link_href = $(this).attr('href');

    if(!(excluded_links.includes(link_href))){
      // If the href doesn't include the regular javascript
      // then disable the link before redirection
      $(this).disable;
    }
  });

  //On click of an anchor link
  $('form').submit(function(e){
    // Find the submit button and disable it
    $(this).find(':submit').disable;
    $(this).find('button').disable;
  });
});
